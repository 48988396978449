import {connect} from "react-redux";
import React, {Component} from "react";
import {rejectIfAnyNull} from "../controllers/utilities/env-checks";
import {Button} from "semantic-ui-react";
import {formatQuery} from "../controllers/utilities/string-utils";
import KaisakuApi from "../controllers/kaisaku-api";
import {navigate} from "../../.cache/gatsby-browser-entry";
import cookie from "react-cookies";
import Helmet from "react-helmet";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ready: false,
            message:""
        };
        // rejectIfAnyNull(this.props.session, this.props.transactionId);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getDirection);
    }

    getDirection = () => {
        this.setState({ state: this.state });
    }

    onReturn(){
        const {transactionId} = formatQuery(this.props.location.search);
        navigate(`/credit-purchase-result/?transactionId=${transactionId}`);
    }

    onContinue() {
        let {pendingTransactionId, newAccessToken, newGameId, newAttributionId} = formatQuery(this.props.location.search);
        if (((newAccessToken === undefined) && (newAccessToken !== null)) || ((newGameId === undefined) && (newGameId !== null))){
            newAccessToken = cookie.load("_accessToken");
            newGameId = cookie.load("_gameId");
            newAttributionId = cookie.load("_attributionId");
            pendingTransactionId = cookie.load("_transactionId");
        }
        if ((newAccessToken !== undefined) && (newGameId !== undefined) && (newAccessToken !== null) && (newGameId !== null)){
            let platform = cookie.load("_platform");
            const url = `/confirm-purchase/?gameId=${newGameId}&attributionId=${newAttributionId}&transactionId=${pendingTransactionId}&accessToken=${newAccessToken}&platform=${platform}`;
            // console.log(url);
            window.location.href = url;
        }else {
            navigate("/confirm-purchase");
        }
    }

    onGameSupport() {
        const {transactionId} = formatQuery(this.props.location.search);
        // navigate(`/game-support/?transactionId=${transactionId}`);
        navigate(`/payment-support-addticket/?transactionId=${transactionId}`);
    }

    async componentDidMount() {
        window.addEventListener('resize', this.getDirection);
        let {transactionId, message} = formatQuery(this.props.location.search);
        if (transactionId === "showqueryorder"){
            transactionId = cookie.load("_datatranstransactionId");
        }
        if (message === undefined) {
            message = "";
        }
        if (transactionId){
            const data = await KaisakuApi.getCreditTransactionDetails(transactionId);
            console.log(data.transaction);
            if (data.transaction){
                if (data.transaction.status !== "Completed"){
                    message = "平台訂單號 " + transactionId + " <br/>已證實不成功扣費，請重新發起支付及付款。<br/>不成功扣費原因有可能是延遲付款";
                }
                if (data.transaction.status === "Completed"){
                    let addmessage = "";
                    if (data.transaction.showTransactionId !== transactionId){
                        addmessage = "<br/>交易訂單 " + data.transaction.showTransactionId;
                    }
                    message = "平台訂單號 " + transactionId + addmessage + " <br/>已增值成功。<br/>請檢查用戶錢包幣，重新點擊確認交易";
                }
            }
        }
        if (!message){
            message = "未知錯誤";
        }
        this.setState({
            ready: true,
            transactionId: transactionId,
            message: message
        });
    }

    render() {
        return (
            <div style={{
                height:"100vh",
                minHeight: '100vh',
                justifyContent: 'center',
                flexDirection: 'column',
                backgroundColor:"#F8F8F6"
            }}>
                <Helmet>
                    <style>{'body:before { background-image:none; background-color: #F8F8F6; }'}</style>
                </Helmet>
                <div style={{width:"100%",height:"100vh", margin:"0 auto"}}>
                    {(this.state.ready) && (<div style={{width:"100vw", fontSize:"1rem", backgroundColor:"#F8F8F6", textAlign:"left", padding:"8px", color:"#888"}}>
                        <div style={{margin:"3vw", width:"90vw", fontSize:"1rem", backgroundColor:"#fff", textAlign:"left", padding:"8px", color:"#888", borderRadius: '0.5rem'}}>
                        <div style={{ paddingTop:"10px", paddingBottom:"10px", textAlign:"left"}}
                             dangerouslySetInnerHTML={{__html:"<p>" + this.state.message + "</p>"}}
                        ></div>
                        <div style={{textAlign:"center", color:"#ff1700", borderTop:"1px solid #F3F3F3", padding:"10px", fontWeight: "bold"}}
                        >如有疑問請截圖聯繫商家</div>
                        </div>
                    </div>)}
                    {(this.state.ready === false) && (<div style={{width:"90vw", fontSize:"1rem", backgroundColor:"#F8F8F6", textAlign:"left", padding:"8px", color:"#888", borderRadius: '0.5rem'}}></div>)

                    }

                    <div style={{width:"90vw",marginLeft:"5vw"}}>
                        <Button color='orange'  fluid
                                style={{marginTop: '1rem', backgroundColor:'#ff704f'}}
                                onClick={this.onGameSupport.bind(this)}
                        >
                            聯繫商家
                        </Button>
                    </div>

                    <div style={{width:"90vw",marginLeft:"5vw"}}>
                        <Button fluid
                                style={{marginTop: '2rem'}}
                                onClick={this.onReturn.bind(this)}
                        >
                            返回帳單詳情
                        </Button>
                    </div>
                </div>
            </div>
        );
    }


});

